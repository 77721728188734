// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-content-page-tsx": () => import("./../src/templates/ContentPage.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-organization-page-tsx": () => import("./../src/templates/OrganizationPage.tsx" /* webpackChunkName: "component---src-templates-organization-page-tsx" */),
  "component---src-templates-city-page-tsx": () => import("./../src/templates/CityPage.tsx" /* webpackChunkName: "component---src-templates-city-page-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../src/templates/NewsPage.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../src/templates/CategoryPage.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cities-tsx": () => import("./../src/pages/cities.tsx" /* webpackChunkName: "component---src-pages-cities-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-hotline-tsx": () => import("./../src/pages/hotline.tsx" /* webpackChunkName: "component---src-pages-hotline-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-organizations-tsx": () => import("./../src/pages/organizations.tsx" /* webpackChunkName: "component---src-pages-organizations-tsx" */),
  "component---src-pages-request-tsx": () => import("./../src/pages/request.tsx" /* webpackChunkName: "component---src-pages-request-tsx" */),
  "component---src-pages-volunteer-tsx": () => import("./../src/pages/volunteer.tsx" /* webpackChunkName: "component---src-pages-volunteer-tsx" */)
}

